/**
 * 公共API
 */
import ajax from './ajaxtwo'

/**
 * 验证码
 * @returns {*}
 */
export function captcha() {
    return ajax({
        url: `/captcha/`,
        method: 'post',
        loading: false,
    })
}

/**
 * 验证码验证
 * @returns {*}
 */
export function captchaVerify(data) {
    return ajax({
        url: `/captcha/verify`,
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        loading: false,
        data
    })
}
