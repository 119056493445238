import CryptoJS from 'crypto-js'

const encodeFactor = '76YQPE5YGJN7F3UQ'

// 加密
export function encrypt(txt) {
  var key = CryptoJS.enc.Utf8.parse(encodeFactor)
  var srcs = CryptoJS.enc.Utf8.parse(txt)
  var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
  return encrypted.toString()
}

// 解密
export function decrypt(txt) {
  var key = CryptoJS.enc.Utf8.parse(encodeFactor)
  var decrypt = CryptoJS.AES.decrypt(txt, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
  return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}