
<template>
  <div>
    <div class="web_login">
      <!-- <div class="login_t"></div> -->

      <Banner></Banner>
      <div class="loginnav">
        <div class="loginnav_min">
          <ul>
            <li><a href="https://www.nahs.org.cn/" target="_blank">首页</a></li>
            <li>
              <a href="https://www.nahs.org.cn/jg/" target="_blank">机构</a>
            </li>
            <li>
              <a href="https://www.nahs.org.cn/dt/" target="_blank">动态</a>
            </li>
            <li>
              <a href="https://www.nahs.org.cn/gk/" target="_blank">公开</a>
            </li>
            <li>
              <a href="https://www.nahs.org.cn/xxcm/myjj/" target="_blank"
                >业务管理</a
              >
            </li>
            <li>
              <a href="https://www.nahs.org.cn/jchsjcm/" target="_blank"
                >生产数据</a
              >
            </li>
            <li>
              <a href="https://www.nahs.org.cn/jcyj/" target="_blank"
                >监测预警</a
              >
            </li>
            <li>
              <a href="https://www.nahs.org.cn/dj/" target="_blank">党建工作</a>
            </li>
            <li>
              <a href="https://www.nahs.org.cn/zt_10027/" target="_blank"
                >专题</a
              >
            </li>
            <li class="active">业务平台</li>
          </ul>
        </div>
      </div>
      <div class="logincontent">
        <div class="content_left">
          <div class="content_left_top">畜牧业综合信息平台</div>
          <div class="content_left_con">
            <p>
              畜牧业综合信息平台是全国畜牧总站业务管理的统一平台，是业务应用系统的统一入口，包括畜牧、种业、饲料、草业四个业务板块。
            </p>
          </div>
        </div>
        <div class="content_right">
          <div class="right_box">
            <div v-show="show">
              <div class="up_top">
                <div class="up_top_img" @click="dian()">
                  <img src="../../assets/shouji.png" alt="" />
                </div>
                <div class="up_top_title">用户登录</div>
              </div>
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item prop="username">
                  <div class="code">
                    <img src="../../assets/编组的副本.png" alt="" />
                  </div>
                  <el-input
                    v-model="ruleForm.username"
                    placeholder="请输入账号"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <div class="code">
                    <img src="../../assets/编组.png" alt="" />
                  </div>

                  <el-input
                    v-model="ruleForm.password"
                    placeholder="请输入密码"
                    show-password
                    @keyup.enter.native="loginbefore"
                  ></el-input>
                </el-form-item>
              </el-form>

              <div class="btnn" @click="loginbefore">登 录</div>
              <div class="btnn_b">
                <span @click="forget" style="cursor: pointer">忘记密码？</span
                ><span @click="goRegister" style="cursor: pointer"
                  >立即注册</span
                >
              </div>
              <div class="rukou">
                <span
                  ><a
                    href="https://iam.agri.cn/idp/oauth2/authorize?redirect_uri=https://home.nahs.org.cn/home&state=123456&client_id=XMZH&response_type=code"
                    ><img src="../../assets/rukou.png" alt="" /></a
                ></span>
              </div>
            </div>
            <div v-show="!show">
              <div class="up_top">
                <div class="up_top_img" @click="dian1()">
                  <img src="../../assets/diannao.png" alt="" />
                </div>
                <div class="up_top_title">手机登录</div>
              </div>
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item prop="phone">
                  <div class="code">
                    <img src="../../assets/编组的副本.png" alt="" />
                  </div>
                  <el-input
                    v-model="ruleForm.phone"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <div class="code">
                    <img src="../../assets/编组.png" alt="" />
                  </div>
                  <div class="text" @click="loginbefore">获取验证码</div>
                  <el-input
                    v-model="ruleForm.code"
                    placeholder="请输入验证码"
                    @keyup.enter.native="login"
                  ></el-input>
                </el-form-item>
              </el-form>

              <div class="btnn" @click="login">登 录</div>
              <div class="btnn_b">
                <span @click="forget" style="cursor: pointer">忘记密码？</span
                ><span @click="goRegister" style="cursor: pointer"
                  >立即注册</span
                >
              </div>
              <div class="rukou">
                <span
                  ><a
                    href="https://iam.agri.cn/idp/oauth2/authorize?redirect_uri=https://home.nahs.org.cn/home&state=123456&client_id=XMZH&response_type=code"
                    ><img src="../../assets/rukou.png" alt="" /></a
                ></span>
              </div>
            </div>

            <PuzzleCode
              :bind="$attrs"
              :show="showVcode"
              success-text="验证成功"
              fail-text="验证失败"
              slider-text="拖动滑块完成拼图"
              @success="success"
              @close="close"
            />
          </div>
        </div>
      </div>

      <!-- <div class="tk">
        <div class="tkbg"></div>
        <div class="tkmain">
          <div class="tkmiddle">
            <div class="tktitle">
              <span>中国畜牧兽医信息网提醒您：</span>
            </div>
            <div class="tkcon">
              <div class="tkcon1">您正在离开本站！</div>
              <div class="tkcon2">
                <span class="tkcon3" onclick="tk3()">确定</span>
                <span class="tkcon4" onclick="tk4()">取消</span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import Banner from "@/components/banner/banner.vue";
import PuzzleCode from "@/components/PuzzleCode2";
import { encrypt } from "@/utils/aes";
export default {
  components: {
    Banner,
    PuzzleCode,
  },

  data() {
    return {
      showVcode: false,
      show:true,
      show1:false,
      ruleForm: {
        password: "",
        username: "",
        phone: "",
        code: "",
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      activeName: "first",
    };
  },
  created() {
    this.getSysUser();
    this.getUserInfo();
  },
  methods: {
    dian(){
      this.show=false
      console.log(this.show,'用户登录')
    },
    dian1(){
      this.show=true
      console.log(this.show,'手机登录')
    },
    getSysUser() {
      this.$http({
        method: "post",
        url: "/home/getUser",
      })
        .then((res) => {
          if (res.errorCode == 200) {
            this.$router.push({ path: "/Portalbackup" });
          } else if (res.errorCode == 2001) {
            this.$router.push({ path: "/" });
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getUserInfo() {
      let code = this.$route.query.code;
      if (code != null && code != undefined) {
        console.log(code);
        let url = "/bim/getTokenAndUserInfo?code=" + code + "&state=12345";
        this.$http({
          url: url,
        })
          .then((res) => {
            if (res.errorCode == "200") {
              localStorage.setItem(
                "menu",
                encodeURIComponent(JSON.stringify(res.data))
              );
              this.$router.push({ path: "/Portalbackup" });
            } else {
              this.$message.error(res.errorMsg);
            }
          })
          .catch(() => {
            // this.$message.error("授权失败");
          });
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    success(x,width,callback) {
      this.showVcode = false;
      let r = false
      let params = new FormData();
      if (this.show) {
        params.append("oidc_user", this.ruleForm.username);
        params.append("oidcPwd",encrypt(this.ruleForm.password))
        params.append("x", x);
        params.append("width", width);
        console.log(params);
        this.$http
        .post("/bim/Login", params, {
          headers: { "Content-Type": "application/json" },
          xhrFields: { withCredentials: true },
          crossDomain: true,
        })
        .then((res) => {
          console.log("login",res.data);
          if (res.errorCode == "200") {
            r = true
            localStorage.setItem(
              "menu",
              encodeURIComponent(JSON.stringify(res.data))
            );
            this.$router.push({ path: "/Portalbackup" });
          } else {
            if (res.errorCode == "999") {
              this.$message.error(res.data);
            } else {
              this.$message.error(res.errorMsg);
            }
          }
        })
        .catch((res) => {
          r = true
          this.$message.warning("登录失败");
        });
      } else {
        this.$http
        .get("/code/sms2?mobile=" + this.ruleForm.phone+"&x="+x+"&width="+width, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.errorCode == "200") {
            r = true
            this.$message.success("短信发送成功");
          } else {
            this.$message.warning(res.data);
          }
        })
        .catch((res) => {
          r = true
          console.log("发送失败");
        });
      }

      callback(r)
    },
    close() {
      this.showVcode = false;
    },
    loginbefore() {
      this.showVcode = true;
    },
    login() {
      let params = new FormData();

      if (this.show) {
        params.append("oidc_user", this.ruleForm.username);
        params.append("oidcPwd",encrypt(this.ruleForm.password))
        //params.append("oidcPwd", this.ruleForm.password);
        console.log(params);
        //this.loginReturn("/signin", params);
        this.loginReturn("/bim/Login", params);
      } else {
        params.append("mobile", this.ruleForm.phone);
        params.append("smsCode", this.ruleForm.code);
        this.$http
          .post("/code/valid", params, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            if (res.errorCode == "200") {
              this.loginReturn("/authentication/mobile", params);
            } else {
              this.$message.warning(res.errorMsg);
            }
          })
          .catch((res) => {
            console.log("验证失败");
          });
      }
    },
    loginReturn(loginuri, params) {
      this.$http
        .post(loginuri, params, {
          headers: { "Content-Type": "application/json" },
          xhrFields: { withCredentials: true },
          crossDomain: true,
        })
        .then((res) => {
          console.log("login",res.data);
          if (res.errorCode == "200") {
            localStorage.setItem(
              "menu",
              encodeURIComponent(JSON.stringify(res.data))
            );
            this.$router.push({ path: "/Portalbackup" });
          } else {
            if (res.errorCode == "999") {
              this.$message.error(res.data);
            } else {
              this.$message.error(res.errorMsg);
            }
          }
        })
        .catch((res) => {
          this.$message.warning("登录失败");
        });
    },
    goRegister() {
      this.$router.push("/register");
    },
    forget() {
      this.$router.push("/Forgetpassword");
    },
    sendMsgCode() {
      this.$http
        .get("/code/sms?mobile=" + this.ruleForm.phone, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.errorCode == "200") {
            this.$message.success("短信发送成功");
          } else {
            this.$message.warning("短信发送失败");
          }
        })
        .catch((res) => {
          console.log("发送失败");
        });
    },
  },
  mounted() {
    let code = this.$route.query.code;
    if (code) {
      this.$http("bim/getTokenAndUserInfo?code="+code+"&state="+this.$route.query.state).then((res) => {
        localStorage.setItem("menu", encodeURIComponent(JSON.stringify(res.data)));
        this.$router.push({ path: "/Portalbackup" });
      })
      .catch((res) => {
        this.$message.warning("登录失败");
      });
    }
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.active {
  background-color: white;
  color: #3275d6 !important;
}
.code {
  z-index: 99;
  position: absolute;
  left: 15px;
  top: 6px;
  width: 15px;
  height: 16px;
}
.login_t {
  height: 40px;
}
.code img {
  width: 100%;
  height: 100%;
}
.text {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #3275d6;
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 99;
  cursor: pointer;
}

.loginnav {
  height: 56px;
  background-color: #3275d6;
}
.loginnav_min {
  width: 1200px;
  margin: auto;
  height: 56px;
}
.loginnav_min ul {
  display: flex;
  align-items: center;
  height: 56px;
}
.loginnav_min ul > li {
  flex: 1;
  height: 56px;
  display: flex;
  align-items: center;
  height: 56px;
  justify-content: center;
  font-size: 20px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
}
.loginnav_min ul > li > a {
  color: #ffffff;
}
.logincontent {
  width: 1200px;
  margin: auto;
  display: flex;
}
.content_left_top {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275d6;
  margin-top: 40px;
}
.content_left {
  flex: 1;
}
.content_right {
  flex: 1;
}
.content_left_con {
  width: 620px;
  height: 380px;
  margin-right: 50px;
  background-image: url("../../assets/牛.png");
}
.content_left_con p {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #333333;
  padding-top: 20px;
  line-height: 25px;
}
.right_box {
  width: 404px;
}
::v-deep .el-tabs__item {
  font-size: 24px;
  flex: 1;
  text-align: center;
  font-weight: bold;
}
::v-deep .el-tabs__nav-scroll {
  display: flex;
}
::v-deep .el-tabs__nav {
  width: 100%;
  display: flex;
}
.right_box {
  margin-top: 40px;
}
::v-deep .el-form-item__content {
  margin-left: 0px !important;
}
::v-deep .el-form-item {
  display: flex;
}
.btnn {
  width: 100%;
  height: 48px;
  background: #3275d6;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  align-items: center;
  margin-top: 40px;
  cursor: pointer;
}
.btnn_b {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #999999;
  margin-top: 30px;
}
::v-deep .el-tabs__header {
  margin-bottom: 30px;
}
::v-deep .el-form-item__content {
  width: 100%;
}
::v-deep .el-form-item:nth-child(2) {
  margin-top: 20px;
  position: relative;
}
::v-deep .el-input__inner {
  height: 48px;
  border-radius: 24px;
  padding-left: 48px;
}

.btnn_b span:nth-child(2) {
  float: right;
}
.rukou {
  height: 36px;
  margin-top: 24px;
  cursor: pointer;
}
.rukou span {
  width: 208px;
  height: 36px;
  display: inline-block;
  float: right;
}
.rukou span img {
  width: 100%;
  height: 100%;
}
::v-deep .el-input {
  font-size: 16px;
}
.footer {
}
.footer img {
  width: 100%;
}
.up_top {
  display: flex;
  height: 45px;
  position: relative;
  margin-bottom: 26px;
}
.up_top_title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: MicrosoftYaHei;
  color: #333333;
}
.up_top_img {
  position: absolute;
  cursor: pointer;
}
</style>